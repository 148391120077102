import axios, { AxiosResponse } from 'axios';
import {
  CustomerData,
  UpdateCustomerDataMutationVariables,
  CustomerDataInput,
} from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import { getXBrandFromCache } from '../../helpers/get-x-brand-from-cache';
import { getEndpoints } from '../../helpers/configs';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export default async function updateCustomerData(
  root: any,
  variables: UpdateCustomerDataMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<CustomerData | null> {
  try {
    let document;
    if (variables.input.cpf) {
      document = createDocument(variables.input.cpf);
    }

    const data = createData(variables.input, document);
    const endpoints = getEndpoints();
    const url = `${endpoints.api}/v1/mobile/customer/me`;
    const xbrand = getXBrandFromCache(context.cache);
    const response = await axios.patch(url, data, { headers: { 'X-BRAND': xbrand } });

    return createCustomerData(response);
  } catch (error) {
    logError(error, 'Error updating user data');
    throw new Error('Não foi possível atualizar os seus dados. Por favor tente novamente.');
  }
}

function createDocument(cpf: string) {
  return [
    {
      data: cpf,
      documentType: 1,
      numericalId: -1,
    },
  ];
}

function createData(input: CustomerDataInput, document: any) {
  return {
    firstName: input.name,
    document,
  };
}

function createCustomerData(response: AxiosResponse<any>): CustomerData {
  const cpf: string | undefined = getCpfFromResponse(response);
  return {
    __typename: 'CustomerData',
    id: btoa(`CustomerData:${response.data.id}`),
    numericalId: response.data.numericalId,
    name: response.data.firstName,
    email: response.data.email,
    cpf,
    enterpriseUser: response.data.enterpriseUser,
  };
}

function getCpfFromResponse(response: AxiosResponse<any>): string | undefined {
  if (response.data.document) {
    for (const doc of response.data.document) {
      if (doc.documentType === 1) {
        return doc.data;
      }
    }
  }
}
