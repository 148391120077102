import { InMemoryCache } from '@apollo/client';
import result from '../../common/generated/fragment-matcher';
import { GetXBrandDocument, GetXBrandQuery } from '../../common/generated/graphql';
import { getSharedConfig } from '../../common/helpers/configs';

export const localCache = new InMemoryCache({
  possibleTypes: result.possibleTypes,
});

export function initLocalCache() {
  const config = getSharedConfig();
  localCache.writeQuery<GetXBrandQuery>({
    query: GetXBrandDocument,
    data: {
      xbrand: config.defaultBrand,
    },
  });
}

export function resetLocalCache() {
  localCache.reset();
}
