import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    element: {
      padding: theme.spacing(2),
    },
    helpElement: {
      padding: theme.spacing(1),
    },
    helpText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  })
);

export default function ContactUs(): ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.element} display='flex' justifyContent='center' alignContent='center'>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <PhoneIcon className={classes.helpElement} color='primary' />
        <EmailIcon className={classes.helpElement} color='primary' />
      </Box>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <a href='tel: 4003-3668' className={classes.helpText}>
          <Typography>4003-3668</Typography>
        </a>
        <a href='mailto: ajuda@onyo.com' className={classes.helpText}>
          <Typography>ajuda@onyo.com</Typography>
        </a>
      </Box>
    </Box>
  );
}
