import {
  CreateEnterpriseUserMutationVariables,
  Result,
  GetCustomerDataQuery,
  GetCustomerDataDocument,
} from '../../generated/graphql';
import axios, { AxiosError } from 'axios';
import { parseErrorMessage } from '../../helpers/parse-error-msg';
import { getXBrandFromCache } from '../../helpers/get-x-brand-from-cache';
import { getEndpoints } from '../../helpers/configs';
import { ApolloClient, FetchPolicy, InMemoryCache } from '@apollo/client';

export default async function createEnterpriseUser(
  root: any,
  variables: CreateEnterpriseUserMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    const endpoints = getEndpoints();
    const url = `${endpoints.api}/v1/mobile/enterprise-user`;
    const xbrand = getXBrandFromCache(context.cache);
    await axios.post(url, { email: variables.email }, { headers: { 'X-BRAND': xbrand } });
    await refetchAndUpdateCustomerData(context.client);

    return {
      __typename: 'Result',
      success: true,
      errorMsg: null,
    };
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.data?.error === 'onyo.enterprise-user.unknown-domain') {
      return {
        __typename: 'Result',
        success: false,
        errorMsg: 'onyo.enterprise-user.unknown-domain',
      };
    }

    return {
      __typename: 'Result',
      success: false,
      errorMsg: parseErrorMessage(
        'Ocorreu um erro ao registrar o seu e-mail.',
        err?.response?.data?.error
      ),
    };
  }
}

async function refetchAndUpdateCustomerData(client: ApolloClient<any>) {
  const customerDataQuery = await fetchCustomerData(client, 'network-only');
  if (!customerDataQuery?.data.customerData) {
    return;
  }

  client.writeQuery<GetCustomerDataQuery>({
    query: GetCustomerDataDocument,
    data: { customerData: customerDataQuery.data.customerData },
  });
}

async function fetchCustomerData(client: ApolloClient<any>, fetchPolicy?: FetchPolicy) {
  return await client.query<GetCustomerDataQuery>({
    query: GetCustomerDataDocument,
    fetchPolicy,
  });
}
