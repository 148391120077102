import 'firebase/analytics';
import firebase from 'firebase/app';

const firebaseProductionConfig = {
  apiKey: 'AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc',
  authDomain: 'brandapplication.firebaseapp.com',
  databaseURL: 'https://brandapplication.firebaseio.com',
  projectId: 'brandapplication',
  storageBucket: 'brandapplication.appspot.com',
  messagingSenderId: '240285417545',
  appId: '1:240285417545:web:d57569fddb5a320cfc7f98',
  measurementId: 'G-XGGWN4VBLE',
};

const firebaseStagingConfig = {
  apiKey: 'AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc',
  authDomain: 'brandapplication.firebaseapp.com',
  databaseURL: 'https://brandapplication.firebaseio.com',
  projectId: 'brandapplication',
  storageBucket: 'brandapplication.appspot.com',
  messagingSenderId: '240285417545',
  appId: '1:240285417545:web:336bb9c73888bdfafc7f98',
  measurementId: 'G-4RZGL9RE0Q',
};

export function initFirebase() {
  if (process.env.NODE_ENV === 'production') {
    firebase.initializeApp(firebaseProductionConfig);
  } else {
    firebase.initializeApp(firebaseStagingConfig);
  }
}
