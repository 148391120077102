import {
  Box,
  Button,
  Container,
  createStyles,
  Theme,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import flowRight from 'lodash/flowRight';
import React, { Component } from 'react';
import ContactUs from '../contact-us/contact-us';

interface State {
  hasError: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    textElement: {
      padding: theme.spacing(1),
    },
    element: {
      padding: theme.spacing(2),
    },
  });

type WrapperProps = WithStyles<typeof styles>;

const withErrorBoundary = <P extends {}>(WrappedComponent: React.ComponentType<P>) => {
  type Props = P & WrapperProps;

  return class ErrorBoundary extends Component<Props, State> {
    public static getDerivedStateFromError(error: any) {
      return { hasError: true };
    }

    constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
    }

    public componentDidCatch(error: any, errorInfo: any) {
      if (process.env.NODE_ENV !== 'test') {
        console.error(errorInfo, error);
        Sentry.setExtra('errorInfo', errorInfo);
        Sentry.captureException(error);
      }
    }

    public onBackClick() {
      window.location.reload();
    }

    public render() {
      const { classes, ...props } = this.props;

      if (this.state.hasError) {
        return (
          <Container className={classes.root} id='error-boundary'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
            >
              <Typography className={classes.textElement} variant='h5' align='center'>
                Ops
              </Typography>
              <Typography className={classes.textElement} align='center'>
                Ocorreu um erro inesperado. Tente novamente ou entre em contato com o nosso suporte.
              </Typography>

              <ContactUs />

              <Box
                className={classes.element}
                display='flex'
                justifyContent='center'
                alignContent='center'
              >
                <Button color='primary' variant='contained' onClick={this.onBackClick}>
                  Tentar novamente
                </Button>
              </Box>
            </Box>
          </Container>
        );
      }

      return <WrappedComponent {...(props as P)} />;
    }
  };
};

export default flowRight(withStyles(styles), withErrorBoundary);
