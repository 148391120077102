import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { initSentry } from './config/sentry';
import { initAxios } from './config/axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeHandler from './common/components/theme-handler/theme-handler';
import Progress from './common/components/progress/progress';
import PageNotFound from './components/page-not-found/page-not-found';
import { initFirebase } from './config/firebase';
import Startup from './common/components/startup/startup';
import withErrorBoundary from './common/components/error-boundary/error-boundary';
import { globalConfig } from './config/global-config';
import { createApolloClient } from './config/apollo-client';
import { ApolloProvider } from '@apollo/client/react';

const CustomerRedirect = React.lazy(
  () => import('./components/customer-redirect/customer-redirect')
);
const WorkEmailInput = React.lazy(() => import('./components/work-email-input/work-email-input'));
const ValidateCode = React.lazy(() => import('./components/validate-code/validate-code'));
const ValidationSuccess = React.lazy(
  () => import('./components/validation-success/validation-success')
);
const Shopkeeper = React.lazy(() => import('./components/shopkeeper/shopkeeper'));

globalConfig();
initSentry();
initAxios();
initFirebase();
const apolloClient = createApolloClient();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <React.Suspense fallback={<Progress />}>
        <Startup>
          <ThemeHandler>
            <Switch>
              <Route exact={true} path='/' component={withErrorBoundary(CustomerRedirect)} />
              <Route
                exact={true}
                path='/email-input'
                component={withErrorBoundary(WorkEmailInput)}
              />
              <Route
                exact={true}
                path='/validate-code'
                component={withErrorBoundary(ValidateCode)}
              />
              <Route
                exact={true}
                path='/validation-success'
                component={withErrorBoundary(ValidationSuccess)}
              />
              <Route exact={true} path='/shopkeeper' component={withErrorBoundary(Shopkeeper)} />
              <Route component={withErrorBoundary(PageNotFound)} />
            </Switch>
          </ThemeHandler>
        </Startup>
      </React.Suspense>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
