import {
  ValidateCodeMutationVariables,
  Result,
  GetCustomerDataQuery,
  GetCustomerDataDocument,
} from '../../generated/graphql';
import axios, { AxiosError } from 'axios';
import { parseErrorMessage } from '../../helpers/parse-error-msg';
import { getXBrandFromCache } from '../../helpers/get-x-brand-from-cache';
import { getEndpoints } from '../../helpers/configs';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export default async function validateCode(
  root: any,
  variables: ValidateCodeMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    const email = await getCustomerEmail(context.client);
    if (!email) {
      return {
        __typename: 'Result',
        success: false,
        errorMsg:
          'Não foi possível obter os seus dados. Por favor recarregue a página e tente novamente.',
      };
    }

    const endpoints = getEndpoints();
    const url = `${endpoints.api}/v1/mobile/enterprise-user/validate`;
    const xbrand = getXBrandFromCache(context.cache);
    await axios.post(
      url,
      { email, validation_code: variables.code },
      { headers: { 'X-BRAND': xbrand } }
    );

    return {
      __typename: 'Result',
      success: true,
      errorMsg: null,
    };
  } catch (error) {
    const err = error as AxiosError;
    return {
      __typename: 'Result',
      success: false,
      errorMsg: parseErrorMessage(
        'Ocorreu um erro inexperado. Por favor recarregue a página e tente novamente.',
        err?.response?.data?.error
      ),
    };
  }
}

async function getCustomerEmail(client: ApolloClient<any>) {
  const result = await client.query<GetCustomerDataQuery>({
    query: GetCustomerDataDocument,
  });

  return result?.data?.customerData?.email;
}
