import React from 'react';
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    townImg: {
      width: 'auto',
      height: '10rem',
    },
  })
);

export default function TownImg() {
  const classes = useStyles();
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <img src='town2.png' alt='town-img' className={classes.townImg} />
    </Box>
  );
}
