import { Endpoints } from '../../../common/model/config';

export const refeicaoOnlineEndpoints: Endpoints = {
  api: 'https://api.refeicao.online',
  graphql: 'https://graphql.refeicao.online/graphql',
};

export const refeicaoOnlineStagingEndpoints: Endpoints = {
  api: 'https://api.staging.refeicao.online',
  graphql: 'https://graphql.staging.refeicao.online/graphql',
};
