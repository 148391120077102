import { Endpoints } from '../../../common/model/config';

export const eldoradoEndpoints: Endpoints = {
  api: 'https://onyo-api.shoppingeldorado.com.br',
  graphql: 'https://onyo-graphql.shoppingeldorado.com.br/graphql',
};

export const eldoradoStagingEndpoints: Endpoints = {
  api: 'https://onyo-api.staging.shoppingeldorado.com.br',
  graphql: 'https://onyo-graphql.staging.shoppingeldorado.com.br/graphql',
};
