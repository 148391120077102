import config from 'react-global-configuration';
import {
  DEVELOPMENT,
  Endpoints,
  FeatureFlags,
  PRODUCTION,
  SharedConfig,
  STAGING,
} from '../common/model/config';
import {
  c3PrajaEndpoints,
  c3PrajaStagingEndpoints,
} from './application/endpoint-configurations/c3-praja.endpoint.config';
import { developmentEndpoints } from './application/endpoint-configurations/development.endpoint.config';
import {
  eldoradoEndpoints,
  eldoradoStagingEndpoints,
} from './application/endpoint-configurations/eldorado.endpoint.config';
import {
  onyoAppEndpoints,
  onyoAppStagingEndpoints,
} from './application/endpoint-configurations/onyo-app.endpoint.config';
import {
  onyoComEndpoints,
  onyoComStagingEndpoints,
} from './application/endpoint-configurations/onyo-com.endpoint.config';
import {
  refeicaoOnlineEndpoints,
  refeicaoOnlineStagingEndpoints,
} from './application/endpoint-configurations/refeicao-online.endpoint.config';
import { c3PrajaFeatureFlags } from './application/feature-flags/c3-praja-features.config';
import { developmentFeatureFlags } from './application/feature-flags/development-features.config';
import { eldoradoFeatureFlags } from './application/feature-flags/eldorado-features.config';
import { onyoAppFeatureFlags } from './application/feature-flags/onyo-app-features.config';
import { onyoComFeatureFlags } from './application/feature-flags/onyo-com-features.config';
import { refeicaoOnlineFeatureFlags } from './application/feature-flags/refeicao-online-features.config';
import { developmentSharedConfig } from './application/shared-configurations/development.config';
import { productionSharedConfig } from './application/shared-configurations/production.config';
import { stagingSharedConfig } from './application/shared-configurations/staging.config';

export function globalConfig() {
  config.set({
    sharedConfig: getSharedConfig(),
    endpoints: getEndpoints(),
    featureFlags: getFeatureFlags(),
  });
}

function getSharedConfig(): SharedConfig {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case PRODUCTION:
      return productionSharedConfig;
    case STAGING:
      return stagingSharedConfig;
    case DEVELOPMENT:
      return developmentSharedConfig;
    default:
      return developmentSharedConfig;
  }
}

function getEndpoints(): Endpoints {
  const env = process.env.REACT_APP_ENV;
  const hostname = window.location.hostname.toLowerCase();

  if (env === DEVELOPMENT) {
    return developmentEndpoints;
  } else if (hostname.includes('c3praja.com')) {
    return env === PRODUCTION ? c3PrajaEndpoints : c3PrajaStagingEndpoints;
  } else if (hostname.includes('onyo.app')) {
    return env === PRODUCTION ? onyoAppEndpoints : onyoAppStagingEndpoints;
  } else if (hostname.includes('refeicao.online')) {
    return env === PRODUCTION ? refeicaoOnlineEndpoints : refeicaoOnlineStagingEndpoints;
  } else if (hostname.includes('shoppingeldorado.com.br')) {
    return env === PRODUCTION ? eldoradoEndpoints : eldoradoStagingEndpoints;
  } else {
    return env === PRODUCTION ? onyoComEndpoints : onyoComStagingEndpoints;
  }
}

function getFeatureFlags(): FeatureFlags {
  const env = process.env.REACT_APP_ENV;
  const hostname = window.location.hostname.toLowerCase();

  if (env === DEVELOPMENT) {
    return developmentFeatureFlags;
  } else if (hostname.includes('c3praja.com')) {
    return c3PrajaFeatureFlags;
  } else if (hostname.includes('onyo.app')) {
    return onyoAppFeatureFlags;
  } else if (hostname.includes('refeicao.online')) {
    return refeicaoOnlineFeatureFlags;
  } else if (hostname.includes('shoppingeldorado.com.br')) {
    return eldoradoFeatureFlags;
  } else if (hostname.includes('onyo.com')) {
    return onyoComFeatureFlags;
  }

  return onyoAppFeatureFlags;
}
