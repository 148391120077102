import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_THEME } from '../../model/default-theme';

export default function ThemeHandler(props: React.PropsWithChildren<any>): React.ReactElement {
  const location = useLocation();
  const [theme, setTheme] = useState(DEFAULT_THEME);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.primary && parsed.secondary) {
      const newTheme: any = {
        ...theme,
        palette: {
          primary: {
            main: `#${parsed.primary}`,
          },
          secondary: {
            main: `#${parsed.secondary}`,
          },
        },
      };
      setTheme(newTheme);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <MuiThemeProvider theme={createMuiTheme(theme)}>{props.children}</MuiThemeProvider>;
}
