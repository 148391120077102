export function parseErrorMessage(fallbackMsg: string, error?: string) {
  switch (error) {
    case 'onyo.invalid-data':
      return 'Os dados fornecidos são inválidos.';

    case 'onyo.enterprise-user.already-validated':
      return 'Esse usuário já está validado.';

    case 'onyo.enterprise-user.already-registered':
      return 'Esse usuário já foi cadastrado.';

    case 'onyo.enterprise-user.invalid-email':
      return 'Email inválido';

    case 'onyo.enterprise-user.not-found':
      return 'Usuário ou códigio inválidos.';

    case 'onyo.enterprise-user.no-user-list':
      return 'Não há promoções associadadas a essa empresa';

    case 'onyo.enterprise-user.unknown-domain':
      return 'Empresa desconhecida';

    case 'onyo.enterprise-user.validation-expired':
      return 'O código de validação expirou.';

    case 'Could not authenticate user':
      return 'O usuário não pôde ser autenticado';

    default:
      return fallbackMsg;
  }
}
