import { HttpLink } from '@apollo/client';
import { getEndpoints } from '../../common/helpers/configs';

export function createHttpLink() {
  const endpoints = getEndpoints();
  return new HttpLink({
    credentials: 'include',
    uri: endpoints.graphql,
  });
}
