import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
  SetXBrandMutationVariables,
  GetXBrandQuery,
  GetXBrandDocument,
} from '../../generated/graphql';

export default function setXBrand(
  root: any,
  variables: SetXBrandMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): number {
  context.cache.writeQuery<GetXBrandQuery>({
    query: GetXBrandDocument,
    data: {
      xbrand: variables.value,
    },
  });

  return variables.value;
}
