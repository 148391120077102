import { GraphQLRequest, InMemoryCache } from '@apollo/client';
import { getXBrandFromCache } from '../../common/helpers/get-x-brand-from-cache';
import { setContext } from '@apollo/client/link/context';

export const authLink = setContext(
  (req: GraphQLRequest, { headers, cache }: { headers?: any; cache: InMemoryCache }) => {
    const xbrand = getXBrandFromCache(cache);
    const authHeaders: any = {
      ...headers,
      'X-BRAND': xbrand,
    };

    return { headers: authHeaders };
  }
);
