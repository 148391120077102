import { Container, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import TownImg from '../../common/components/town-img/town-img';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    boldText: {
      fontWeight: 500,
    },
    bolderText: {
      fontWeight: 'bold',
    },
  })
);

export default function PageNotFound() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <TownImg />
      <Typography
        variant='h5'
        align='center'
        gutterBottom={true}
        color='primary'
        className={classes.bolderText}
      >
        Página não encontrada
      </Typography>
      <Typography align='center' color='secondary'>
        Clique{' '}
        <Link href='/' color='secondary' className={classes.boldText}>
          aqui
        </Link>{' '}
        para voltar.
      </Typography>
    </Container>
  );
}
