import customerData from '../../common/resolvers/customer-data/customer-data.resolver';
import createEnterpriseUser from '../../common/resolvers/create-enterprise-user/create-enterprise-user.resolver';
import validateCode from '../../common/resolvers/validate-code/validate-code.resolver';
import resendValidationCode from '../../common/resolvers/resend-validation-code/resend-validation-code.resolver';
import sendShopkeeperForm from '../../common/resolvers/send-shopkeeper-form/send-shopkeeper-form.resolver';
import updateCustomerData from '../../common/resolvers/update-customer-data/update-customer-data.resolver';
import setXBrand from '../../common/resolvers/xbrand/set-xbrand.resolver';

export const localResolvers = {
  Query: {
    customerData,
  },
  Mutation: {
    createEnterpriseUser,
    validateCode,
    resendValidationCode,
    sendShopkeeperForm,
    updateCustomerData,
    setXBrand,
  },
};
