import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { CustomerData, EntrUser } from '../../generated/graphql';
import { getEndpoints } from '../../helpers/configs';
import { getXBrandFromCache } from '../../helpers/get-x-brand-from-cache';

export default async function customerData(
  root: any,
  variables: any,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<CustomerData | null> {
  let data = null;
  const dataFromAPI = await getUserDataFromAPI(context.cache);
  data = createCustomerData(dataFromAPI);

  return data;
}

async function getUserDataFromAPI(cache: InMemoryCache): Promise<any> {
  const endpoints = getEndpoints();
  const url = `${endpoints.api}/v1/mobile/customer/me`;
  const xbrand = getXBrandFromCache(cache);
  const response = await axios.get(url, { headers: { 'X-BRAND': xbrand } });
  return response.data;
}

function createCustomerData(data: any): CustomerData {
  const cpf = getCpf(data);
  return {
    __typename: 'CustomerData',
    id: btoa(`CustomerData:${data.id}`),
    numericalId: data.numericalId,
    name: data.firstName,
    email: data.email,
    cpf,
    enterpriseUser: createEnterpriseUser(data),
  };
}

function getCpf(data: any): string | null {
  if (data.document) {
    for (const doc of data.document) {
      if (doc.documentType === 1) {
        return doc.data;
      }
    }
  }
  return null;
}

function createEnterpriseUser(data: any): EntrUser | null {
  if (!data.enterpriseUser) {
    return null;
  }

  return {
    __typename: 'EntrUser',
    status: data.enterpriseUser.status,
    email: data.enterpriseUser.email,
  };
}
