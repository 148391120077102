import { SharedConfig, Endpoints, FeatureFlags } from '../model/config';
import config from 'react-global-configuration';

export function getSharedConfig(): SharedConfig {
  return config.get('sharedConfig');
}

export function getEndpoints(): Endpoints {
  return config.get('endpoints');
}

export function getFeatureFlags(): FeatureFlags {
  return config.get('featureFlags');
}
