import { Endpoints } from '../../../common/model/config';

export const c3PrajaEndpoints: Endpoints = {
  api: 'https://api.c3praja.com',
  graphql: 'https://graphql.c3praja.com/graphql',
};

export const c3PrajaStagingEndpoints: Endpoints = {
  api: 'https://api.staging.c3praja.com',
  graphql: 'https://graphql.staging.c3praja.com/graphql',
};
