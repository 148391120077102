import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const DEFAULT_THEME: ThemeOptions = {
  palette: {
    primary: {
      main: '#3d0180',
      light: '#6f35b0',
      dark: '#060052',
    },
    secondary: {
      main: '#ff4a86',
      light: '#ff81b5',
      dark: '#c7005a',
    },
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
  },
};
