import { Endpoints } from '../../../common/model/config';

export const onyoAppEndpoints: Endpoints = {
  api: 'https://api.onyo.app',
  graphql: 'https://graphql.onyo.app/graphql',
};

export const onyoAppStagingEndpoints: Endpoints = {
  api: 'https://api.staging.onyo.app',
  graphql: 'https://graphql.staging.onyo.app/graphql',
};
