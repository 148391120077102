import React from 'react';
import { Box, CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  })
);

export default function Progress() {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress id='spinner' color='primary' className={classes.progress} />
    </Box>
  );
}
