import 'firebase/analytics';
import queryString from 'query-string';
import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetXBrandMutation } from '../../generated/graphql';
import useFirebaseEvent from '../../hooks/use-firebase-event.hook';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface Props extends PropsWithChildren<any> {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 1),
    },
  })
);

export default function Startup(props: Props): ReactElement {
  const classes = useStyles();
  const [parseXBrandDone, setParseXBrandDone] = useState(false);
  const location = useLocation();
  const [setXBrand] = useSetXBrandMutation();

  useFirebaseEvent('Enterprise_User_Form_Open');

  useEffect(() => {
    const parseXbrand = async () => {
      const qsValues = queryString.parse(location.search);
      const xbrand = Number(qsValues?.xbrand);

      if (xbrand && !isNaN(xbrand)) {
        await setXBrand({ variables: { value: xbrand } });
      }

      setParseXBrandDone(true);
    };

    parseXbrand();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!parseXBrandDone) {
    return <div id='parsing-xbrand' />;
  }

  return <div className={classes.root}>{props.children}</div>;
}
