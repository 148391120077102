import {
  Result,
  SendShopkeeperFormMutationVariables,
  WorkplaceType,
} from '../../generated/graphql';
import axios from 'axios';
import { logError } from '../../helpers/log-error';
import { getOnlyDigits } from '../../helpers/get-only-digits';
import { getXBrandFromCache } from '../../helpers/get-x-brand-from-cache';
import { getEndpoints } from '../../helpers/configs';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export default async function sendShopkeeperForm(
  root: any,
  variables: SendShopkeeperFormMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    const endpoints = getEndpoints();
    const url = `${endpoints.api}/v1/shopkeepers`;
    const xbrand = getXBrandFromCache(context.cache);
    await axios.post(url, getFormData(variables), { headers: { 'X-BRAND': xbrand } });
    return { __typename: 'Result', success: true, errorMsg: null };
  } catch (error) {
    logError(error, 'Error in Shopkeepers Call');
    return {
      __typename: 'Result',
      success: false,
      errorMsg: 'Erro ao enviar seus dados, por favor tente novamente',
    };
  }
}

function getFormData(variables: SendShopkeeperFormMutationVariables) {
  const data = new FormData();
  data.append('name', variables.input.name);
  data.append('email', variables.input.email);
  data.append('enterprise_email', variables.input.enterpriseEmail);
  data.append('shopping_name', variables.input.shoppingName);
  data.append('workplace_type', getWorkplaceTypeForRequest(variables.input.workplaceType));
  data.append('workplace_name', variables.input.workplaceName);
  data.append('cpf', getOnlyDigits(variables.input.cpf));
  data.append('image', variables.input.inputFile);
  return data;
}

function getWorkplaceTypeForRequest(workplaceType: WorkplaceType) {
  switch (workplaceType) {
    case WorkplaceType.Store:
      return '1';
    case WorkplaceType.Company:
      return '2';
    default:
      throw new Error(`Unexpected workplace type ${workplaceType}`);
  }
}
