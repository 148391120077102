import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  File: any;
};

export type Query = {
  __typename?: 'Query';
  aggregator?: Maybe<CompanyGroup>;
  aggregators?: Maybe<CompanyGroupConnection>;
  allOrdersByDays?: Maybe<OrderConnection>;
  brandTheme?: Maybe<BrandTheme>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  company?: Maybe<Company>;
  crmUser?: Maybe<AccountsUser>;
  customerData?: Maybe<CustomerData>;
  defaultBrandTheme?: Maybe<BrandTheme>;
  lastOrders?: Maybe<OrderConnection>;
  listBrands?: Maybe<BrandConnection>;
  listCategories?: Maybe<CategoryConnection>;
  listCompanies?: Maybe<CompanyConnection>;
  listMenuCategories?: Maybe<MenuCategoryConnection>;
  listMenuCompanyProducts?: Maybe<MenuProductCompanyConnection>;
  listProductDetails?: Maybe<Array<Maybe<Product>>>;
  listProductsByBrand?: Maybe<ProductConnection>;
  me?: Maybe<Person>;
  menuProductCompanyById?: Maybe<MenuProductCompany>;
  menuPublishState?: Maybe<MenuPublishStatus>;
  mobileParameter?: Maybe<Parameter>;
  node?: Maybe<Node>;
  order?: Maybe<Order>;
  productCompanies?: Maybe<ProductCompanyConnection>;
  productCompanyById?: Maybe<ProductCompany>;
  productDetails?: Maybe<Product>;
  userListPromotions?: Maybe<PromotionConnection>;
  xbrand: Scalars['Int'];
};

export type QueryAggregatorArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  aggregatorId: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumDistanceKilometers?: Maybe<Scalars['Float']>;
  filterBy?: Maybe<AggregatorFilterOption>;
  orderType?: Maybe<Array<Maybe<OrderTypeFilterOptionEnum>>>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
};

export type QueryAggregatorsArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumDistanceKilometers: Scalars['Float'];
  filterBy?: Maybe<AggregatorFilterOption>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAllOrdersByDaysArgs = {
  days: Scalars['Int'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryBrandThemeArgs = {
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
};

export type QueryBrandsArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
};

export type QueryLastOrdersArgs = {
  fromDatetime: Scalars['DateTime'];
  toDatetime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Maybe<OrderStatusCode>>>;
  brand?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListBrandsArgs = {
  sort?: Maybe<Array<Maybe<BrandSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCategoriesArgs = {
  brandId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<CategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCompaniesArgs = {
  sort?: Maybe<Array<Maybe<CompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCategoriesArgs = {
  brandId: Scalars['Int'];
  sort?: Maybe<Array<Maybe<MenuCategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCompanyProductsArgs = {
  companyId: Scalars['Int'];
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<MenuProductCompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListProductDetailsArgs = {
  productIds: Array<Maybe<Scalars['Int']>>;
  companyId: Scalars['Int'];
};

export type QueryListProductsByBrandArgs = {
  brandId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  sort?: Maybe<Array<Maybe<ProductSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryMenuProductCompanyByIdArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryMenuPublishStateArgs = {
  companyId: Scalars['Int'];
};

export type QueryMobileParameterArgs = {
  companyId: Scalars['Int'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['Int'];
};

export type QueryProductCompaniesArgs = {
  companyId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  productHidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryProductCompanyByIdArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type QueryProductDetailsArgs = {
  productId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryUserListPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  corporateName?: Maybe<Scalars['String']>;
  menuId?: Maybe<Scalars['Int']>;
  isDigitalMenu?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['Int']>;
  isLgpdCompliance?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  document?: Maybe<Document>;
  openingTimes?: Maybe<TimeRule>;
  address?: Maybe<Address>;
  category?: Maybe<CompanyCategory>;
  companyPhone?: Maybe<CompanyPhone>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  productCompany?: Maybe<ProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  onlineStatus?: Maybe<OnlineStatus>;
  highlight?: Maybe<CompanyHighlight>;
  images?: Maybe<Array<Maybe<Image>>>;
  parameter?: Maybe<Parameter>;
  preparationTime?: Maybe<PreparationTime>;
  aggregator?: Maybe<CompanyGroup>;
  delivery?: Maybe<Delivery>;
};

export type CompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Brand = Node & {
  __typename?: 'Brand';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  brandTheme?: Maybe<BrandThemeConnection>;
  category?: Maybe<CategoryConnection>;
  company?: Maybe<CompanySharedConnection>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  menuProduct?: Maybe<MenuProductConnection>;
  products?: Maybe<ProductConnection>;
  promotions?: Maybe<PromotionConnection>;
  timeRule?: Maybe<TimeRuleConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Image>>>;
  reference?: Maybe<Scalars['String']>;
};

export type BrandBrandThemeArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandThemeConnection = {
  __typename?: 'BrandThemeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandThemeEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `BrandTheme` and its cursor. */
export type BrandThemeEdge = {
  __typename?: 'BrandThemeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandTheme>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BrandTheme = Node & {
  __typename?: 'BrandTheme';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandThemeColor?: Maybe<Array<Maybe<BrandThemeColor>>>;
  brandThemeMessage?: Maybe<Array<Maybe<BrandThemeMessage>>>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type BrandThemeColor = Node & {
  __typename?: 'BrandThemeColor';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BrandThemeMessage = Node & {
  __typename?: 'BrandThemeMessage';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryEdge>>;
};

/** A Relay edge containing a `Category` and its cursor. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<Category>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Category = Node & {
  __typename?: 'Category';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  products?: Maybe<ProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type CategoryMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoryProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuCategoryConnection = {
  __typename?: 'MenuCategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuCategoryEdge>>;
};

/** A Relay edge containing a `MenuCategory` and its cursor. */
export type MenuCategoryEdge = {
  __typename?: 'MenuCategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuCategory>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MenuCategory = Node & {
  __typename?: 'MenuCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendCategoryId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  backendCategory?: Maybe<Category>;
  menuProduct?: Maybe<MenuProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuCategoryMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum MenuPublishStatus {
  Pending = 'PENDING',
  Publishing = 'PUBLISHING',
  Published = 'PUBLISHED',
}

export type MenuProductConnection = {
  __typename?: 'MenuProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductEdge>>;
};

/** A Relay edge containing a `MenuProduct` and its cursor. */
export type MenuProductEdge = {
  __typename?: 'MenuProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProduct>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MenuProduct = Node & {
  __typename?: 'MenuProduct';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  brand?: Maybe<Brand>;
  category?: Maybe<MenuCategory>;
  backendProduct?: Maybe<Product>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuProductMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Product = Node & {
  __typename?: 'Product';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  timeRuleId?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Image>>>;
  sequence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  rewardable?: Maybe<Scalars['Boolean']>;
  redeemable?: Maybe<Scalars['Boolean']>;
  minimumChoices?: Maybe<Scalars['Int']>;
  maximumChoices?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<ProductType>;
  hidden?: Maybe<Scalars['Boolean']>;
  recommended?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  feedbackable?: Maybe<Scalars['Boolean']>;
  posUpdated?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Scalars['Boolean']>;
  isNote?: Maybe<Scalars['Boolean']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  category?: Maybe<Category>;
  timeRule?: Maybe<TimeRule>;
  menuProduct?: Maybe<MenuProductConnection>;
  productCompany?: Maybe<ProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  subproducts?: Maybe<Array<Maybe<Product>>>;
  productCompanyByCompanyId?: Maybe<ProductCompany>;
};

export type ProductMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Image = {
  __typename?: 'Image';
  context?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  cropId?: Maybe<Scalars['Int']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  cropWidth?: Maybe<Scalars['Int']>;
  cropHeight?: Maybe<Scalars['Int']>;
  originalImageUrl?: Maybe<Scalars['String']>;
  originalImageWidth?: Maybe<Scalars['Int']>;
  originalImageHeight?: Maybe<Scalars['Int']>;
  originalImageId?: Maybe<Scalars['Int']>;
};

export enum ProductType {
  Simple = 'SIMPLE',
  Choosable = 'CHOOSABLE',
  MenuItem = 'MENU_ITEM',
}

export type TimeRule = Node & {
  __typename?: 'TimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  calendarTimeRule?: Maybe<CalendarTimeRuleConnection>;
  company?: Maybe<Company>;
  exceptionTimeRule?: Maybe<ExceptionTimeRuleConnection>;
  products?: Maybe<ProductConnection>;
  weekdayTimeRule?: Maybe<WeekedayTimeRuleConnection>;
};

export type TimeRuleCalendarTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleExceptionTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleWeekdayTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CalendarTimeRuleConnection = {
  __typename?: 'CalendarTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarTimeRuleEdge>>;
};

/** A Relay edge containing a `CalendarTimeRule` and its cursor. */
export type CalendarTimeRuleEdge = {
  __typename?: 'CalendarTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<CalendarTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CalendarTimeRule = Node & {
  __typename?: 'CalendarTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  dayFrom?: Maybe<Scalars['String']>;
  dayTo?: Maybe<Scalars['String']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type ExceptionTimeRuleConnection = {
  __typename?: 'ExceptionTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExceptionTimeRuleEdge>>;
};

/** A Relay edge containing a `ExceptionTimeRule` and its cursor. */
export type ExceptionTimeRuleEdge = {
  __typename?: 'ExceptionTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExceptionTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ExceptionTimeRule = Node & {
  __typename?: 'ExceptionTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductEdge>>;
};

/** A Relay edge containing a `Product` and its cursor. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<Product>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WeekedayTimeRuleConnection = {
  __typename?: 'WeekedayTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WeekedayTimeRuleEdge>>;
};

/** A Relay edge containing a `WeekedayTimeRule` and its cursor. */
export type WeekedayTimeRuleEdge = {
  __typename?: 'WeekedayTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<WeekedayTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WeekedayTimeRule = Node & {
  __typename?: 'WeekedayTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type ProductCompanyConnection = {
  __typename?: 'ProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProductCompany` and its cursor. */
export type ProductCompanyEdge = {
  __typename?: 'ProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductCompany = Node & {
  __typename?: 'ProductCompany';
  /** The ID of the object. */
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  posPrice?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  redeemPoints?: Maybe<Scalars['Int']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  isMenuException?: Maybe<Scalars['Boolean']>;
  path: Scalars['JSONString'];
  product?: Maybe<Product>;
  company?: Maybe<Company>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductCompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuProductCompanyConnection = {
  __typename?: 'MenuProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MenuProductCompany` and its cursor. */
export type MenuProductCompanyEdge = {
  __typename?: 'MenuProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MenuProductCompany = Node & {
  __typename?: 'MenuProductCompany';
  /** The ID of the object. */
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductCompanyId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  product?: Maybe<MenuProduct>;
  company?: Maybe<Company>;
  backendProductCompany?: Maybe<ProductCompany>;
  numericalId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CompanySharedConnection = {
  __typename?: 'CompanySharedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanySharedEdge>>;
};

/** A Relay edge containing a `CompanyShared` and its cursor. */
export type CompanySharedEdge = {
  __typename?: 'CompanySharedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PromotionEdge>>;
};

/** A Relay edge containing a `Promotion` and its cursor. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Promotion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Promotion = Node & {
  __typename?: 'Promotion';
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  discountOnyo?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  restrict?: Maybe<Scalars['Boolean']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  uniqueUse?: Maybe<Scalars['Boolean']>;
  applyToAllBrandCompanies?: Maybe<Scalars['Boolean']>;
  applyToAllCompaniesProducts?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  title?: Maybe<Scalars['String']>;
  companies?: Maybe<CompanySharedConnection>;
};

export type PromotionCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleConnection = {
  __typename?: 'TimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeRuleEdge>>;
};

/** A Relay edge containing a `TimeRule` and its cursor. */
export type TimeRuleEdge = {
  __typename?: 'TimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Document = Node & {
  __typename?: 'Document';
  /** The ID of the object. */
  id: Scalars['ID'];
  documentType?: Maybe<DocumentType>;
  data?: Maybe<Scalars['String']>;
  expedition?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  customerDocumentSet?: Maybe<CostumerDocumentConnection>;
};

export type DocumentCustomerDocumentSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DocumentType {
  Cpf = 'CPF',
  Cnpj = 'CNPJ',
  Identity = 'IDENTITY',
}

export type CostumerDocumentConnection = {
  __typename?: 'CostumerDocumentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CostumerDocumentEdge>>;
};

/** A Relay edge containing a `CostumerDocument` and its cursor. */
export type CostumerDocumentEdge = {
  __typename?: 'CostumerDocumentEdge';
  /** The item at the end of the edge */
  node?: Maybe<CostumerDocument>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CostumerDocument = Node & {
  __typename?: 'CostumerDocument';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  document?: Maybe<Document>;
};

export type Customer = Node & {
  __typename?: 'Customer';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userPtrId: Scalars['ID'];
  telephone?: Maybe<Scalars['String']>;
  validationCellphone?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  googleplusToken?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  loginAttemptCount?: Maybe<Scalars['Int']>;
  accountsUser?: Maybe<AccountsUser>;
  card?: Maybe<CardConnection>;
  order?: Maybe<OrderConnection>;
  customerDocumentSet?: Maybe<Array<Maybe<CostumerDocument>>>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type CustomerCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerOrderArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AccountsUser = Node & {
  __typename?: 'AccountsUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  allowedCompanies?: Maybe<Array<Maybe<Company>>>;
  allowedBrands?: Maybe<Array<Maybe<Brand>>>;
  allowedCompaniesByBrand?: Maybe<Array<Maybe<Company>>>;
};

export type AccountsUserAllowedCompaniesByBrandArgs = {
  brandId: Scalars['Int'];
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  accountsUser?: Maybe<AccountsUser>;
  customer?: Maybe<Customer>;
};

export type CardConnection = {
  __typename?: 'CardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CardEdge>>;
};

/** A Relay edge containing a `Card` and its cursor. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Card>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Card = Node & {
  __typename?: 'Card';
  /** The ID of the object. */
  id: Scalars['ID'];
  printedName?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  pinValidated?: Maybe<Scalars['Boolean']>;
  maskedNumber?: Maybe<Scalars['String']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<CardBrand>;
  customer?: Maybe<Customer>;
};

export type CardBrand = Node & {
  __typename?: 'CardBrand';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  card?: Maybe<CardConnection>;
};

export type CardBrandCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderEdge>>;
};

/** A Relay edge containing a `Order` and its cursor. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** The item at the end of the edge */
  node?: Maybe<Order>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Order = Node & {
  __typename?: 'Order';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
  originBrandId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  retryId?: Maybe<Scalars['Int']>;
  ownerUserAgentInfo?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  posImportStatus?: Maybe<Scalars['Int']>;
  lastStatusReceivedMobile?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['Int']>;
  posStatus?: Maybe<Scalars['Int']>;
  posStatusDatetime?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  antifraudStatus?: Maybe<Scalars['Int']>;
  antifraudStatusDatetime?: Maybe<Scalars['DateTime']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledDatetime?: Maybe<Scalars['DateTime']>;
  paymentAuthorizedDatetime?: Maybe<Scalars['DateTime']>;
  posReceivedDatetime?: Maybe<Scalars['DateTime']>;
  posAcceptedDatetime?: Maybe<Scalars['DateTime']>;
  posDeniedDatetime?: Maybe<Scalars['DateTime']>;
  readyDatetime?: Maybe<Scalars['DateTime']>;
  dispatchedDatetime?: Maybe<Scalars['DateTime']>;
  deliveredDatetime?: Maybe<Scalars['DateTime']>;
  receivedDatetime?: Maybe<Scalars['DateTime']>;
  consumedDatetime?: Maybe<Scalars['DateTime']>;
  posImportedDatetime?: Maybe<Scalars['DateTime']>;
  posImportErrorDatetime?: Maybe<Scalars['DateTime']>;
  preparingDatetime?: Maybe<Scalars['DateTime']>;
  posAnalysingDatetime?: Maybe<Scalars['DateTime']>;
  productUnavailableDatetime?: Maybe<Scalars['DateTime']>;
  customerActionNeededDatetime?: Maybe<Scalars['DateTime']>;
  printed?: Maybe<Scalars['Boolean']>;
  printedDatetime?: Maybe<Scalars['DateTime']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  lastMobileUpdateDatetime?: Maybe<Scalars['DateTime']>;
  lastUpdateDatetime?: Maybe<Scalars['DateTime']>;
  prepareRightaway?: Maybe<Scalars['Boolean']>;
  ownedLoyaltyPoints?: Maybe<Scalars['Int']>;
  usedLoyaltyPoints?: Maybe<Scalars['Int']>;
  printId?: Maybe<Scalars['String']>;
  tableReference?: Maybe<Scalars['String']>;
  barCode?: Maybe<Scalars['String']>;
  displayCode?: Maybe<Scalars['String']>;
  receiptMessage?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  payableValue?: Maybe<Scalars['Float']>;
  discountPercentual?: Maybe<Scalars['Float']>;
  discountValue?: Maybe<Scalars['Float']>;
  subsidyValue?: Maybe<Scalars['Float']>;
  observation?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['String']>;
  taxPercentual?: Maybe<Scalars['Float']>;
  taxValue?: Maybe<Scalars['Float']>;
  serviceChargePercentual?: Maybe<Scalars['Float']>;
  serviceChargeValue?: Maybe<Scalars['Float']>;
  hasRedeem?: Maybe<Scalars['Boolean']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  isMock?: Maybe<Scalars['Boolean']>;
  received?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  owner?: Maybe<Customer>;
  originBrand?: Maybe<Brand>;
  address?: Maybe<Address>;
  orderExtras?: Maybe<OrderExtraConnection>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  orderExtra?: Maybe<Array<Maybe<OrderExtra>>>;
};

export type OrderOrderExtrasArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Address = Node & {
  __typename?: 'Address';
  /** The ID of the object. */
  id: Scalars['ID'];
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
};

export type OrderExtraConnection = {
  __typename?: 'OrderExtraConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderExtraEdge>>;
};

/** A Relay edge containing a `OrderExtra` and its cursor. */
export type OrderExtraEdge = {
  __typename?: 'OrderExtraEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderExtra>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderExtra = Node & {
  __typename?: 'OrderExtra';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  optional?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
};

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPosPrice?: Maybe<Scalars['Float']>;
  productType?: Maybe<ProductType>;
  registerDatetime?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  redeemId?: Maybe<Scalars['Int']>;
  posParentId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  productCompanyItemId?: Maybe<Scalars['Int']>;
  billItemId?: Maybe<Scalars['JSONString']>;
  orderPosition?: Maybe<Scalars['Int']>;
  draggedBy?: Maybe<Scalars['Int']>;
  isPackage?: Maybe<Scalars['Boolean']>;
  combo?: Maybe<Scalars['Boolean']>;
  isNote?: Maybe<Scalars['Boolean']>;
  offer?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  itemPosReference?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  baseUnitPrice?: Maybe<Scalars['Float']>;
  baseUnitPosPrice?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  unitPoints?: Maybe<Scalars['Int']>;
  partition?: Maybe<Scalars['Int']>;
  prepaidRewardPoints?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  /** The ID of the object. */
  id: Scalars['ID'];
  value?: Maybe<Scalars['Float']>;
  method?: Maybe<PaymentMethodEnum>;
  card?: Maybe<Card>;
};

export enum PaymentMethodEnum {
  Card = 'CARD',
  Coupon = 'COUPON',
  Redeem = 'REDEEM',
  PrepaidRedeem = 'PREPAID_REDEEM',
  Wallet = 'WALLET',
  Offline = 'OFFLINE',
}

export type BalanceConnection = {
  __typename?: 'BalanceConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BalanceEdge>>;
};

/** A Relay edge containing a `Balance` and its cursor. */
export type BalanceEdge = {
  __typename?: 'BalanceEdge';
  /** The item at the end of the edge */
  node?: Maybe<Balance>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Balance = {
  __typename?: 'Balance';
  brand?: Maybe<Brand>;
  points?: Maybe<Scalars['Int']>;
};

export type RewardConnection = {
  __typename?: 'RewardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RewardEdge>>;
};

/** A Relay edge containing a `Reward` and its cursor. */
export type RewardEdge = {
  __typename?: 'RewardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Reward>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Reward = Node & {
  __typename?: 'Reward';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  temporaryUserId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  availablePoints?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  temporaryUser?: Maybe<TemporaryUser>;
  brand?: Maybe<Brand>;
};

export type TemporaryUser = Node & {
  __typename?: 'TemporaryUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  couponId?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  validated?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type TemporaryUserBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TemporaryUserRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyCategory = Node & {
  __typename?: 'CompanyCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type CompanyPhone = Node & {
  __typename?: 'CompanyPhone';
  /** The ID of the object. */
  id: Scalars['ID'];
  number: Scalars['String'];
};

export enum OnlineStatus {
  Online = 'ONLINE',
  Closed = 'CLOSED',
  Offline = 'OFFLINE',
}

export type CompanyHighlight = {
  __typename?: 'CompanyHighlight';
  numericalId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
};

export type Parameter = Node & {
  __typename?: 'Parameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyGroupId?: Maybe<Scalars['Int']>;
  barcodeEnabled?: Maybe<Scalars['Boolean']>;
  mailOrderCancellation?: Maybe<Scalars['String']>;
  mailReplyTo?: Maybe<Scalars['String']>;
  productChangeDestination?: Maybe<Scalars['String']>;
  mailSenderName?: Maybe<Scalars['String']>;
  mailOrderConfirmation?: Maybe<Scalars['String']>;
  mockOrderEmail?: Maybe<Scalars['String']>;
  feedbackCommentsDestination?: Maybe<Scalars['String']>;
  orderDisplaycodeRule?: Maybe<Scalars['String']>;
  orderDisplaycodeNumber?: Maybe<Scalars['Int']>;
  redeemPointsAutomatic?: Maybe<Scalars['Boolean']>;
  allowRewardByProduct?: Maybe<Scalars['Boolean']>;
  allowRedeemNoChoosable?: Maybe<Scalars['Boolean']>;
  allowFirstPaymentInStore?: Maybe<Scalars['Boolean']>;
  rewardByCpf?: Maybe<Scalars['Boolean']>;
  rewardFeedbackListValue?: Maybe<Scalars['Int']>;
  orderActive?: Maybe<Scalars['Boolean']>;
  paymentActive?: Maybe<Scalars['Boolean']>;
  invitationListActive?: Maybe<Scalars['Boolean']>;
  posBillOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posGeneralOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posOrderOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posTimeout?: Maybe<Scalars['Int']>;
  paymentIntegrationTimeout?: Maybe<Scalars['Int']>;
  productsCacheMaxAge?: Maybe<Scalars['JSONString']>;
  aggregatorCacheMaxAge?: Maybe<Scalars['JSONString']>;
  antifraudActive?: Maybe<Scalars['Boolean']>;
  allowCreditRewards?: Maybe<Scalars['Boolean']>;
  onyoAffiliationBalanceThreshold?: Maybe<Scalars['Float']>;
  onyoAffiliationEnabled?: Maybe<Scalars['Boolean']>;
  loyaltyActive?: Maybe<Scalars['Boolean']>;
  mobileCard?: Maybe<Scalars['JSONString']>;
  mobileClaim?: Maybe<Scalars['JSONString']>;
  mobileFeedback?: Maybe<Scalars['JSONString']>;
  mobileHome?: Maybe<Scalars['JSONString']>;
  mobileModules?: Maybe<Scalars['JSONString']>;
  mobileOrder?: Maybe<Scalars['JSONString']>;
  mobileTutorial?: Maybe<Scalars['JSONString']>;
  paymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
  numericalId?: Maybe<Scalars['Int']>;
  orderTypes?: Maybe<Array<Maybe<OrderTypeParameter>>>;
  offlinePaymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
};

export type PaymentParameter = Node & {
  __typename?: 'PaymentParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
  cardBrand?: Maybe<CardBrand>;
  requiresCpf?: Maybe<Scalars['Boolean']>;
};

export type OrderTypeParameter = Node & {
  __typename?: 'OrderTypeParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  additionalPreparationTime?: Maybe<Scalars['Int']>;
  orderType?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  additionalChargeParameter?: Maybe<Array<Maybe<AdditionalChargeParameter>>>;
  orderFields?: Maybe<Array<Maybe<OrderFieldParameter>>>;
};

export type AdditionalChargeParameter = Node & {
  __typename?: 'AdditionalChargeParameter';
  optional?: Maybe<Scalars['Boolean']>;
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  additionalPriceType?: Maybe<AdditionalChargePriceTypeEnum>;
  additionalValue?: Maybe<Scalars['Float']>;
  additionalType?: Maybe<AdditionalChargeTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum AdditionalChargePriceTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export enum AdditionalChargeTypeEnum {
  Delivery = 'DELIVERY',
  Package = 'PACKAGE',
  Other = 'OTHER',
}

export type OrderFieldParameter = Node & {
  __typename?: 'OrderFieldParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  field?: Maybe<OrderField>;
};

export type OrderField = Node & {
  __typename?: 'OrderField';
  /** The ID of the object. */
  id: Scalars['ID'];
  mapping?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  fieldType?: Maybe<OrderFieldType>;
  title?: Maybe<Scalars['String']>;
  requiredErrorMessage?: Maybe<Scalars['String']>;
  invalidErrorMessage?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericalId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

export enum OrderFieldType {
  Text = 'TEXT',
  Numeric = 'NUMERIC',
  Telephone = 'TELEPHONE',
  Cpf = 'CPF',
  OptionList = 'OPTION_LIST',
}

export type PreparationTime = {
  __typename?: 'PreparationTime';
  minTime?: Maybe<Scalars['Int']>;
  maxTime?: Maybe<Scalars['Int']>;
  additionalTime?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyDemandStatus>;
};

export enum CompanyDemandStatus {
  Unknown = 'UNKNOWN',
  Normal = 'NORMAL',
  High = 'HIGH',
  Blocked = 'BLOCKED',
}

export type CompanyGroup = Node & {
  __typename?: 'CompanyGroup';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  isShoppingCenter?: Maybe<Scalars['Boolean']>;
  isEvent?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  numericalId?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanySharedConnection>;
  images?: Maybe<Array<Maybe<Image>>>;
};

export type CompanyGroupCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Delivery = {
  __typename?: 'Delivery';
  distanceCalculated?: Maybe<Scalars['Float']>;
  distancePriceReference?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  firstLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  secondLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
};

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  AddressIdAsc = 'ADDRESS_ID_ASC',
  AddressIdDesc = 'ADDRESS_ID_DESC',
  CorporateNameAsc = 'CORPORATE_NAME_ASC',
  CorporateNameDesc = 'CORPORATE_NAME_DESC',
  MenuIdAsc = 'MENU_ID_ASC',
  MenuIdDesc = 'MENU_ID_DESC',
  IsDigitalMenuAsc = 'IS_DIGITAL_MENU_ASC',
  IsDigitalMenuDesc = 'IS_DIGITAL_MENU_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  IsLgpdComplianceAsc = 'IS_LGPD_COMPLIANCE_ASC',
  IsLgpdComplianceDesc = 'IS_LGPD_COMPLIANCE_DESC',
}

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandEdge>>;
};

/** A Relay edge containing a `Brand` and its cursor. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The item at the end of the edge */
  node?: Maybe<Brand>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum BrandSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LayoutAsc = 'LAYOUT_ASC',
  LayoutDesc = 'LAYOUT_DESC',
}

/** An enumeration. */
export enum CategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type CompanyGroupConnection = {
  __typename?: 'CompanyGroupConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyGroupEdge>>;
};

/** A Relay edge containing a `CompanyGroup` and its cursor. */
export type CompanyGroupEdge = {
  __typename?: 'CompanyGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGroup>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum AggregatorFilterOption {
  DeliveryArea = 'DELIVERY_AREA',
}

export enum OrderTypeFilterOptionEnum {
  Counter = 'COUNTER',
  ToGo = 'TO_GO',
  Curbside = 'CURBSIDE',
  Table = 'TABLE',
  Delivery = 'DELIVERY',
  Card = 'CARD',
  Payment = 'PAYMENT',
  Coupon = 'COUPON',
  RoomService = 'ROOM_SERVICE',
  PrepaidPoints = 'PREPAID_POINTS',
  TruckStop = 'TRUCK_STOP',
  Event = 'EVENT',
}

export type Person = Customer | TemporaryUser;

export enum OrderStatusCode {
  Canceled = 'CANCELED',
  BackendReceived = 'BACKEND_RECEIVED',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PosReceived = 'POS_RECEIVED',
  PosAccepted = 'POS_ACCEPTED',
  PosDenied = 'POS_DENIED',
  Ready = 'READY',
  Dispatched = 'DISPATCHED',
  Delivered = 'DELIVERED',
  Received = 'RECEIVED',
  Consumed = 'CONSUMED',
  PosImported = 'POS_IMPORTED',
  PosImportError = 'POS_IMPORT_ERROR',
  Preparing = 'PREPARING',
  PosAnalysing = 'POS_ANALYSING',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
  CustomerActionNeeded = 'CUSTOMER_ACTION_NEEDED',
}

/** An enumeration. */
export enum ProductSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  TimeRuleIdAsc = 'TIME_RULE_ID_ASC',
  TimeRuleIdDesc = 'TIME_RULE_ID_DESC',
  ImagesAsc = 'IMAGES_ASC',
  ImagesDesc = 'IMAGES_DESC',
  SequenceAsc = 'SEQUENCE_ASC',
  SequenceDesc = 'SEQUENCE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  FullDescriptionAsc = 'FULL_DESCRIPTION_ASC',
  FullDescriptionDesc = 'FULL_DESCRIPTION_DESC',
  PreparationTimeMinimumAsc = 'PREPARATION_TIME_MINIMUM_ASC',
  PreparationTimeMinimumDesc = 'PREPARATION_TIME_MINIMUM_DESC',
  PreparationTimeMaximumAsc = 'PREPARATION_TIME_MAXIMUM_ASC',
  PreparationTimeMaximumDesc = 'PREPARATION_TIME_MAXIMUM_DESC',
  RewardableAsc = 'REWARDABLE_ASC',
  RewardableDesc = 'REWARDABLE_DESC',
  RedeemableAsc = 'REDEEMABLE_ASC',
  RedeemableDesc = 'REDEEMABLE_DESC',
  MinimumChoicesAsc = 'MINIMUM_CHOICES_ASC',
  MinimumChoicesDesc = 'MINIMUM_CHOICES_DESC',
  MaximumChoicesAsc = 'MAXIMUM_CHOICES_ASC',
  MaximumChoicesDesc = 'MAXIMUM_CHOICES_DESC',
  OfferAsc = 'OFFER_ASC',
  OfferDesc = 'OFFER_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  RecommendedAsc = 'RECOMMENDED_ASC',
  RecommendedDesc = 'RECOMMENDED_DESC',
  BarcodeAsc = 'BARCODE_ASC',
  BarcodeDesc = 'BARCODE_DESC',
  FeedbackableAsc = 'FEEDBACKABLE_ASC',
  FeedbackableDesc = 'FEEDBACKABLE_DESC',
  PosUpdatedAsc = 'POS_UPDATED_ASC',
  PosUpdatedDesc = 'POS_UPDATED_DESC',
  GroupAsc = 'GROUP_ASC',
  GroupDesc = 'GROUP_DESC',
  IsNoteAsc = 'IS_NOTE_ASC',
  IsNoteDesc = 'IS_NOTE_DESC',
  PosNameAsc = 'POS_NAME_ASC',
  PosNameDesc = 'POS_NAME_DESC',
  PosReferenceAsc = 'POS_REFERENCE_ASC',
  PosReferenceDesc = 'POS_REFERENCE_DESC',
  OperatorAsc = 'OPERATOR_ASC',
  OperatorDesc = 'OPERATOR_DESC',
}

/** An enumeration. */
export enum MenuProductCompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendProductCompanyIdAsc = 'BACKEND_PRODUCT_COMPANY_ID_ASC',
  BackendProductCompanyIdDesc = 'BACKEND_PRODUCT_COMPANY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

/** An enumeration. */
export enum MenuCategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendCategoryIdAsc = 'BACKEND_CATEGORY_ID_ASC',
  BackendCategoryIdDesc = 'BACKEND_CATEGORY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

export type Mutations = {
  __typename?: 'Mutations';
  backendMenuDeleteProduct?: Maybe<BackendMenuDeleteProduct>;
  backendMenuDeleteProductPictures?: Maybe<BackendMenuDeleteProductPictures>;
  backendMenuReorderProduct?: Maybe<BackendMenuReorderProduct>;
  backendMenuUpsertItem?: Maybe<BackendMenuUpsertItem>;
  backendSubproductRemoveItem?: Maybe<BackendSubproductRemoveItem>;
  companiesUpdateOnlineOfflineBatch?: Maybe<CompaniesUpdateOnlineOfflineBatch>;
  companyWeekdayTimeRules?: Maybe<CompanyWeekdayTimeRules>;
  companyWeekdayTimeRulesBatch?: Maybe<CompanyWeekdayTimeRulesBatch>;
  createAccountsUser?: Maybe<CreateAccountsUser>;
  createEnterpriseUser: Result;
  createMenuBatch?: Maybe<CreateMenuBatch>;
  customerEngagementSignUp?: Maybe<CustomerEngagementSignUp>;
  deleteBrandCroppedPicture?: Maybe<DeleteBrandCroppedPicture>;
  deleteCompanyCroppedPicture?: Maybe<DeleteCompanyCroppedPicture>;
  hiddenAllProductsByCategory?: Maybe<HiddenAllProductsByCategory>;
  loginTokenAuth?: Maybe<LoginTokenAuth>;
  menuCreateOrUpdateItem?: Maybe<MenuCreateOrUpdateItem>;
  menuCsv?: Maybe<MenuCsv>;
  menuDeleteItem?: Maybe<MenuDeleteItem>;
  menuPublish?: Maybe<MenuPublish>;
  metabaseResourceUrl?: Maybe<MetabaseResourceUrl>;
  node?: Maybe<Node>;
  refreshAccessToken?: Maybe<RefreshAccessToken>;
  resendValidationCode: Result;
  resetAccountsPassword?: Maybe<ResetPassword>;
  saveBrandPictures?: Maybe<SaveBrandPictures>;
  saveCompanyPictures?: Maybe<SaveCompanyPictures>;
  selfJourneyRegister?: Maybe<DigitalMenuRegisterMutation>;
  sendShopkeeperForm: Result;
  setXBrand: Scalars['Int'];
  updateBrandCroppedPicture?: Maybe<UpdateBrandCroppedPicture>;
  updateCompanyCroppedPicture?: Maybe<UpdateCompanyCroppedPicture>;
  updateCustomerData?: Maybe<CustomerData>;
  validateCode: Result;
};

export type MutationsBackendMenuDeleteProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productCompanyId: Scalars['Int'];
};

export type MutationsBackendMenuDeleteProductPicturesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  croppedPictureIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productId: Scalars['Int'];
};

export type MutationsBackendMenuReorderProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  sequence: Scalars['Int'];
};

export type MutationsBackendMenuUpsertItemArgs = {
  backendMenuUpsertItemInput: BackendMenuUpsertItemInput;
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  imageFile?: Maybe<Scalars['Upload']>;
  imageInput?: Maybe<CroppedPictureInput>;
};

export type MutationsBackendSubproductRemoveItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  subproductId: Scalars['Int'];
};

export type MutationsCompaniesUpdateOnlineOfflineBatchArgs = {
  brandId: Scalars['Int'];
  companiesItems: Array<Maybe<CompaniesUpdateOnlineOfflineBatchInput>>;
};

export type MutationsCompanyWeekdayTimeRulesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  input: Array<Maybe<CompanyWeekdayTimeRulesInput>>;
};

export type MutationsCompanyWeekdayTimeRulesBatchArgs = {
  brandId: Scalars['Int'];
  schedules: Array<Maybe<CompanyWeekdayTimeRulesScheduleInput>>;
};

export type MutationsCreateAccountsUserArgs = {
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsCreateEnterpriseUserArgs = {
  email: Scalars['String'];
};

export type MutationsCreateMenuBatchArgs = {
  brandId: Scalars['Int'];
  companiesIds: Array<Maybe<Scalars['Int']>>;
  isOpenDeliveryIntegration?: Maybe<Scalars['Boolean']>;
  menu: Array<Maybe<ProductBatchInput>>;
};

export type MutationsCustomerEngagementSignUpArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsDeleteBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsDeleteCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsHiddenAllProductsByCategoryArgs = {
  categoryId: Scalars['Int'];
  companyId: Scalars['Int'];
  hidden: Scalars['Boolean'];
};

export type MutationsLoginTokenAuthArgs = {
  input: LoginAuthInput;
};

export type MutationsMenuCreateOrUpdateItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCreateOrUpdateItemInput: MenuCreateOrUpdateItemInput;
};

export type MutationsMenuCsvArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationsMenuDeleteItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCategoryId?: Maybe<Scalars['Int']>;
  menuProductId?: Maybe<Scalars['Int']>;
};

export type MutationsMenuPublishArgs = {
  companyId: Scalars['Int'];
};

export type MutationsMetabaseResourceUrlArgs = {
  input: MetabaseResourceUrlInput;
};

export type MutationsNodeArgs = {
  id: Scalars['ID'];
};

export type MutationsResetAccountsPasswordArgs = {
  email: Scalars['String'];
};

export type MutationsSaveBrandPicturesArgs = {
  brand: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsSaveCompanyPicturesArgs = {
  company: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsSelfJourneyRegisterArgs = {
  selfJourneyData: SelfJourneyInput;
};

export type MutationsSendShopkeeperFormArgs = {
  input: ShopkeeperFormInput;
};

export type MutationsSetXBrandArgs = {
  value: Scalars['Int'];
};

export type MutationsUpdateBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsUpdateCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsUpdateCustomerDataArgs = {
  input: CustomerDataInput;
};

export type MutationsValidateCodeArgs = {
  code: Scalars['Int'];
};

export type LoginTokenAuth = {
  __typename?: 'LoginTokenAuth';
  accessToken?: Maybe<Scalars['String']>;
};

export type LoginAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  userType: AuthUserType;
};

export enum AuthUserType {
  Crm = 'CRM',
}

export type RefreshAccessToken = {
  __typename?: 'RefreshAccessToken';
  accessToken?: Maybe<Scalars['String']>;
};

export type MetabaseResourceUrl = {
  __typename?: 'MetabaseResourceUrl';
  url?: Maybe<Scalars['String']>;
};

export type MetabaseResourceUrlInput = {
  resourceName: Scalars['String'];
  resourceId: Scalars['Int'];
  companies?: Maybe<Scalars['String']>;
  brands?: Maybe<Scalars['String']>;
};

export type CustomerEngagementSignUp = {
  __typename?: 'CustomerEngagementSignUp';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type UpdateBrandCroppedPicture = {
  __typename?: 'UpdateBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type UpdateCroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  picture: Scalars['String'];
  target: Scalars['String'];
};

export type UpdateCompanyCroppedPicture = {
  __typename?: 'UpdateCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type DeleteBrandCroppedPicture = {
  __typename?: 'DeleteBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type DeleteCompanyCroppedPicture = {
  __typename?: 'DeleteCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type SaveBrandPictures = {
  __typename?: 'SaveBrandPictures';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type SavePicturesInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  context: Scalars['String'];
  cropId?: Maybe<Scalars['Int']>;
};

export type SaveCompanyPictures = {
  __typename?: 'SaveCompanyPictures';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type CompanyWeekdayTimeRules = {
  __typename?: 'CompanyWeekdayTimeRules';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type CompanyWeekdayTimeRulesInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
  operation: CompanyWeekdayTimeRulesOperation;
};

export enum CompanyWeekdayTimeRulesOperation {
  Update = 'UPDATE',
  Create = 'CREATE',
  Delete = 'DELETE',
}

export type MenuCsv = {
  __typename?: 'MenuCSV';
  valid?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['GenericScalar']>;
};

export type CreateAccountsUser = {
  __typename?: 'CreateAccountsUser';
  data?: Maybe<Customer>;
};

export type MenuPublish = {
  __typename?: 'MenuPublish';
  valid?: Maybe<Scalars['Boolean']>;
};

export type DigitalMenuRegisterMutation = {
  __typename?: 'DigitalMenuRegisterMutation';
  token?: Maybe<Scalars['String']>;
};

export type SelfJourneyInput = {
  accounts: AccountsInput;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  corporateName: Scalars['String'];
  brandName: Scalars['String'];
  cnpj: Scalars['String'];
  telephone: Scalars['String'];
  address: AddressInput;
  brandImageInput?: Maybe<SavePicturesInput>;
  brandImageFile?: Maybe<Scalars['Upload']>;
};

export type AccountsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  geoLat: Scalars['Float'];
  geoLon: Scalars['Float'];
};

export type MenuCreateOrUpdateItem = {
  __typename?: 'MenuCreateOrUpdateItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type MenuCreateOrUpdateItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MenuDeleteItem = {
  __typename?: 'MenuDeleteItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type BackendMenuUpsertItem = {
  __typename?: 'BackendMenuUpsertItem';
  category?: Maybe<Category>;
  product?: Maybe<Product>;
  productCompany?: Maybe<ProductCompany>;
};

export type BackendMenuUpsertItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  choosables?: Maybe<Array<Maybe<BackendMenuUpsertChoosablesInput>>>;
};

export type BackendMenuUpsertChoosablesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  simples?: Maybe<Array<Maybe<BackendMenuUpsertSimplesInput>>>;
};

export type BackendMenuUpsertSimplesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type CroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
};

export type BackendMenuDeleteProduct = {
  __typename?: 'BackendMenuDeleteProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuDeleteProductPictures = {
  __typename?: 'BackendMenuDeleteProductPictures';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendSubproductRemoveItem = {
  __typename?: 'BackendSubproductRemoveItem';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompanyWeekdayTimeRulesBatch = {
  __typename?: 'CompanyWeekdayTimeRulesBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompanyWeekdayTimeRulesScheduleInput = {
  companyId: Scalars['Int'];
  schedule: Array<Maybe<TimeRulesScheduleInput>>;
};

export type TimeRulesScheduleInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
};

export type CompaniesUpdateOnlineOfflineBatch = {
  __typename?: 'CompaniesUpdateOnlineOfflineBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompaniesUpdateOnlineOfflineBatchInput = {
  id: Scalars['Int'];
  unavailable: Scalars['Boolean'];
};

export type CreateMenuBatch = {
  __typename?: 'CreateMenuBatch';
  menuItems?: Maybe<Array<Maybe<ProductCompany>>>;
};

export type ProductBatchInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productCategoryOrder?: Maybe<Scalars['Int']>;
  productImageUrl?: Maybe<Scalars['String']>;
  choosables?: Maybe<Array<Maybe<ChoosableInput>>>;
};

export type ChoosableInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productCategoryOrder?: Maybe<Scalars['Int']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
  simples: Array<Maybe<SimpleInput>>;
};

export type SimpleInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productCategoryOrder?: Maybe<Scalars['Int']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
};

export type BackendMenuReorderProduct = {
  __typename?: 'BackendMenuReorderProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type HiddenAllProductsByCategory = {
  __typename?: 'HiddenAllProductsByCategory';
  success?: Maybe<Scalars['Boolean']>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  id: Scalars['ID'];
  numericalId: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  cpf?: Maybe<Scalars['String']>;
  enterpriseUser?: Maybe<EntrUser>;
};

export type CustomerDataInput = {
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

export type EntrUser = {
  __typename?: 'EntrUser';
  status: Scalars['Int'];
  email: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  success: Scalars['Boolean'];
  errorMsg?: Maybe<Scalars['String']>;
};

export enum WorkplaceType {
  Store = 'STORE',
  Company = 'COMPANY',
}

export type ShopkeeperFormInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  enterpriseEmail: Scalars['String'];
  shoppingName: Scalars['String'];
  workplaceType: WorkplaceType;
  workplaceName: Scalars['String'];
  cpf: Scalars['String'];
  inputFile: Scalars['File'];
};

export type CreateEnterpriseUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateEnterpriseUserMutation = { __typename?: 'Mutations' } & {
  createEnterpriseUser: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type CustomerDataFragment = { __typename: 'CustomerData' } & Pick<
  CustomerData,
  'id' | 'name' | 'email' | 'cpf'
> & { enterpriseUser?: Maybe<{ __typename: 'EntrUser' } & Pick<EntrUser, 'status' | 'email'>> };

export type GetBrandThemeQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetBrandThemeQuery = { __typename?: 'Query' } & {
  brandTheme?: Maybe<
    { __typename?: 'BrandTheme' } & Pick<BrandTheme, 'id'> & {
        brandThemeColor?: Maybe<
          Array<Maybe<{ __typename?: 'BrandThemeColor' } & Pick<BrandThemeColor, 'key' | 'value'>>>
        >;
      }
  >;
};

export type GetCustomerDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerDataQuery = { __typename?: 'Query' } & {
  customerData?: Maybe<{ __typename?: 'CustomerData' } & CustomerDataFragment>;
};

export type GetUserPromotionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPromotionsQuery = { __typename?: 'Query' } & {
  userListPromotions?: Maybe<
    { __typename: 'PromotionConnection' } & {
      edges: Array<
        Maybe<
          { __typename: 'PromotionEdge' } & {
            node?: Maybe<
              { __typename: 'Promotion' } & Pick<Promotion, 'id' | 'title' | 'isActive'> & {
                  brand?: Maybe<
                    { __typename: 'Brand' } & Pick<Brand, 'id' | 'name'> & {
                        images?: Maybe<
                          Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>
                        >;
                        company?: Maybe<
                          { __typename: 'CompanySharedConnection' } & {
                            edges: Array<
                              Maybe<
                                { __typename: 'CompanySharedEdge' } & {
                                  node?: Maybe<
                                    { __typename: 'Company' } & Pick<
                                      Company,
                                      'id' | 'name' | 'hidden'
                                    > & {
                                        aggregator?: Maybe<
                                          { __typename: 'CompanyGroup' } & Pick<
                                            CompanyGroup,
                                            'id' | 'name'
                                          >
                                        >;
                                      }
                                  >;
                                }
                              >
                            >;
                          }
                        >;
                      }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type GetXBrandQueryVariables = Exact<{ [key: string]: never }>;

export type GetXBrandQuery = { __typename?: 'Query' } & Pick<Query, 'xbrand'>;

export type ResendValidationCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ResendValidationCodeMutation = { __typename?: 'Mutations' } & {
  resendValidationCode: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type SendShopkeeperFormMutationVariables = Exact<{
  input: ShopkeeperFormInput;
}>;

export type SendShopkeeperFormMutation = { __typename?: 'Mutations' } & {
  sendShopkeeperForm: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type SetXBrandMutationVariables = Exact<{
  value: Scalars['Int'];
}>;

export type SetXBrandMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'setXBrand'>;

export type UpdateCustomerDataMutationVariables = Exact<{
  input: CustomerDataInput;
}>;

export type UpdateCustomerDataMutation = { __typename?: 'Mutations' } & {
  updateCustomerData?: Maybe<{ __typename?: 'CustomerData' } & CustomerDataFragment>;
};

export type ValidateCodeMutationVariables = Exact<{
  code: Scalars['Int'];
}>;

export type ValidateCodeMutation = { __typename?: 'Mutations' } & {
  validateCode: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export const CustomerDataFragmentDoc = gql`
  fragment customerData on CustomerData {
    id
    __typename
    name
    email
    cpf
    enterpriseUser {
      __typename
      status
      email
    }
  }
`;
export const CreateEnterpriseUserDocument = gql`
  mutation CreateEnterpriseUser($email: String!) {
    createEnterpriseUser(email: $email) @client {
      success
      errorMsg
    }
  }
`;
export type CreateEnterpriseUserMutationFn = Apollo.MutationFunction<
  CreateEnterpriseUserMutation,
  CreateEnterpriseUserMutationVariables
>;

/**
 * __useCreateEnterpriseUserMutation__
 *
 * To run a mutation, you first call `useCreateEnterpriseUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnterpriseUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnterpriseUserMutation, { data, loading, error }] = useCreateEnterpriseUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateEnterpriseUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEnterpriseUserMutation,
    CreateEnterpriseUserMutationVariables
  >
) {
  return Apollo.useMutation<CreateEnterpriseUserMutation, CreateEnterpriseUserMutationVariables>(
    CreateEnterpriseUserDocument,
    baseOptions
  );
}
export type CreateEnterpriseUserMutationHookResult = ReturnType<
  typeof useCreateEnterpriseUserMutation
>;
export type CreateEnterpriseUserMutationResult = Apollo.MutationResult<
  CreateEnterpriseUserMutation
>;
export type CreateEnterpriseUserMutationOptions = Apollo.BaseMutationOptions<
  CreateEnterpriseUserMutation,
  CreateEnterpriseUserMutationVariables
>;
export const GetBrandThemeDocument = gql`
  query GetBrandTheme($brandId: Int!) {
    brandTheme(brandId: $brandId) {
      id
      brandThemeColor {
        key
        value
      }
    }
  }
`;

/**
 * __useGetBrandThemeQuery__
 *
 * To run a query within a React component, call `useGetBrandThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandThemeQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetBrandThemeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBrandThemeQuery, GetBrandThemeQueryVariables>
) {
  return Apollo.useQuery<GetBrandThemeQuery, GetBrandThemeQueryVariables>(
    GetBrandThemeDocument,
    baseOptions
  );
}
export function useGetBrandThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBrandThemeQuery, GetBrandThemeQueryVariables>
) {
  return Apollo.useLazyQuery<GetBrandThemeQuery, GetBrandThemeQueryVariables>(
    GetBrandThemeDocument,
    baseOptions
  );
}
export type GetBrandThemeQueryHookResult = ReturnType<typeof useGetBrandThemeQuery>;
export type GetBrandThemeLazyQueryHookResult = ReturnType<typeof useGetBrandThemeLazyQuery>;
export type GetBrandThemeQueryResult = Apollo.QueryResult<
  GetBrandThemeQuery,
  GetBrandThemeQueryVariables
>;
export const GetCustomerDataDocument = gql`
  query GetCustomerData {
    customerData @client {
      ...customerData
    }
  }
  ${CustomerDataFragmentDoc}
`;

/**
 * __useGetCustomerDataQuery__
 *
 * To run a query within a React component, call `useGetCustomerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerDataQuery, GetCustomerDataQueryVariables>
) {
  return Apollo.useQuery<GetCustomerDataQuery, GetCustomerDataQueryVariables>(
    GetCustomerDataDocument,
    baseOptions
  );
}
export function useGetCustomerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDataQuery, GetCustomerDataQueryVariables>
) {
  return Apollo.useLazyQuery<GetCustomerDataQuery, GetCustomerDataQueryVariables>(
    GetCustomerDataDocument,
    baseOptions
  );
}
export type GetCustomerDataQueryHookResult = ReturnType<typeof useGetCustomerDataQuery>;
export type GetCustomerDataLazyQueryHookResult = ReturnType<typeof useGetCustomerDataLazyQuery>;
export type GetCustomerDataQueryResult = Apollo.QueryResult<
  GetCustomerDataQuery,
  GetCustomerDataQueryVariables
>;
export const GetUserPromotionsDocument = gql`
  query GetUserPromotions {
    userListPromotions {
      __typename
      edges {
        __typename
        node {
          id
          __typename
          title
          isActive
          brand {
            id
            __typename
            name
            images {
              __typename
              context
              url
            }
            company {
              __typename
              edges {
                __typename
                node {
                  id
                  __typename
                  name
                  hidden
                  aggregator {
                    __typename
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserPromotionsQuery__
 *
 * To run a query within a React component, call `useGetUserPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPromotionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserPromotionsQuery, GetUserPromotionsQueryVariables>
) {
  return Apollo.useQuery<GetUserPromotionsQuery, GetUserPromotionsQueryVariables>(
    GetUserPromotionsDocument,
    baseOptions
  );
}
export function useGetUserPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPromotionsQuery, GetUserPromotionsQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserPromotionsQuery, GetUserPromotionsQueryVariables>(
    GetUserPromotionsDocument,
    baseOptions
  );
}
export type GetUserPromotionsQueryHookResult = ReturnType<typeof useGetUserPromotionsQuery>;
export type GetUserPromotionsLazyQueryHookResult = ReturnType<typeof useGetUserPromotionsLazyQuery>;
export type GetUserPromotionsQueryResult = Apollo.QueryResult<
  GetUserPromotionsQuery,
  GetUserPromotionsQueryVariables
>;
export const GetXBrandDocument = gql`
  query GetXBrand {
    xbrand @client
  }
`;

/**
 * __useGetXBrandQuery__
 *
 * To run a query within a React component, call `useGetXBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXBrandQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetXBrandQuery(
  baseOptions?: Apollo.QueryHookOptions<GetXBrandQuery, GetXBrandQueryVariables>
) {
  return Apollo.useQuery<GetXBrandQuery, GetXBrandQueryVariables>(GetXBrandDocument, baseOptions);
}
export function useGetXBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetXBrandQuery, GetXBrandQueryVariables>
) {
  return Apollo.useLazyQuery<GetXBrandQuery, GetXBrandQueryVariables>(
    GetXBrandDocument,
    baseOptions
  );
}
export type GetXBrandQueryHookResult = ReturnType<typeof useGetXBrandQuery>;
export type GetXBrandLazyQueryHookResult = ReturnType<typeof useGetXBrandLazyQuery>;
export type GetXBrandQueryResult = Apollo.QueryResult<GetXBrandQuery, GetXBrandQueryVariables>;
export const ResendValidationCodeDocument = gql`
  mutation ResendValidationCode {
    resendValidationCode @client {
      success
      errorMsg
    }
  }
`;
export type ResendValidationCodeMutationFn = Apollo.MutationFunction<
  ResendValidationCodeMutation,
  ResendValidationCodeMutationVariables
>;

/**
 * __useResendValidationCodeMutation__
 *
 * To run a mutation, you first call `useResendValidationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendValidationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendValidationCodeMutation, { data, loading, error }] = useResendValidationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendValidationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendValidationCodeMutation,
    ResendValidationCodeMutationVariables
  >
) {
  return Apollo.useMutation<ResendValidationCodeMutation, ResendValidationCodeMutationVariables>(
    ResendValidationCodeDocument,
    baseOptions
  );
}
export type ResendValidationCodeMutationHookResult = ReturnType<
  typeof useResendValidationCodeMutation
>;
export type ResendValidationCodeMutationResult = Apollo.MutationResult<
  ResendValidationCodeMutation
>;
export type ResendValidationCodeMutationOptions = Apollo.BaseMutationOptions<
  ResendValidationCodeMutation,
  ResendValidationCodeMutationVariables
>;
export const SendShopkeeperFormDocument = gql`
  mutation SendShopkeeperForm($input: ShopkeeperFormInput!) {
    sendShopkeeperForm(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type SendShopkeeperFormMutationFn = Apollo.MutationFunction<
  SendShopkeeperFormMutation,
  SendShopkeeperFormMutationVariables
>;

/**
 * __useSendShopkeeperFormMutation__
 *
 * To run a mutation, you first call `useSendShopkeeperFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendShopkeeperFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendShopkeeperFormMutation, { data, loading, error }] = useSendShopkeeperFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendShopkeeperFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendShopkeeperFormMutation,
    SendShopkeeperFormMutationVariables
  >
) {
  return Apollo.useMutation<SendShopkeeperFormMutation, SendShopkeeperFormMutationVariables>(
    SendShopkeeperFormDocument,
    baseOptions
  );
}
export type SendShopkeeperFormMutationHookResult = ReturnType<typeof useSendShopkeeperFormMutation>;
export type SendShopkeeperFormMutationResult = Apollo.MutationResult<SendShopkeeperFormMutation>;
export type SendShopkeeperFormMutationOptions = Apollo.BaseMutationOptions<
  SendShopkeeperFormMutation,
  SendShopkeeperFormMutationVariables
>;
export const SetXBrandDocument = gql`
  mutation SetXBrand($value: Int!) {
    setXBrand(value: $value) @client
  }
`;
export type SetXBrandMutationFn = Apollo.MutationFunction<
  SetXBrandMutation,
  SetXBrandMutationVariables
>;

/**
 * __useSetXBrandMutation__
 *
 * To run a mutation, you first call `useSetXBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetXBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setXBrandMutation, { data, loading, error }] = useSetXBrandMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetXBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<SetXBrandMutation, SetXBrandMutationVariables>
) {
  return Apollo.useMutation<SetXBrandMutation, SetXBrandMutationVariables>(
    SetXBrandDocument,
    baseOptions
  );
}
export type SetXBrandMutationHookResult = ReturnType<typeof useSetXBrandMutation>;
export type SetXBrandMutationResult = Apollo.MutationResult<SetXBrandMutation>;
export type SetXBrandMutationOptions = Apollo.BaseMutationOptions<
  SetXBrandMutation,
  SetXBrandMutationVariables
>;
export const UpdateCustomerDataDocument = gql`
  mutation UpdateCustomerData($input: CustomerDataInput!) {
    updateCustomerData(input: $input) @client {
      ...customerData
    }
  }
  ${CustomerDataFragmentDoc}
`;
export type UpdateCustomerDataMutationFn = Apollo.MutationFunction<
  UpdateCustomerDataMutation,
  UpdateCustomerDataMutationVariables
>;

/**
 * __useUpdateCustomerDataMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerDataMutation, { data, loading, error }] = useUpdateCustomerDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerDataMutation,
    UpdateCustomerDataMutationVariables
  >
) {
  return Apollo.useMutation<UpdateCustomerDataMutation, UpdateCustomerDataMutationVariables>(
    UpdateCustomerDataDocument,
    baseOptions
  );
}
export type UpdateCustomerDataMutationHookResult = ReturnType<typeof useUpdateCustomerDataMutation>;
export type UpdateCustomerDataMutationResult = Apollo.MutationResult<UpdateCustomerDataMutation>;
export type UpdateCustomerDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerDataMutation,
  UpdateCustomerDataMutationVariables
>;
export const ValidateCodeDocument = gql`
  mutation ValidateCode($code: Int!) {
    validateCode(code: $code) @client {
      success
      errorMsg
    }
  }
`;
export type ValidateCodeMutationFn = Apollo.MutationFunction<
  ValidateCodeMutation,
  ValidateCodeMutationVariables
>;

/**
 * __useValidateCodeMutation__
 *
 * To run a mutation, you first call `useValidateCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCodeMutation, { data, loading, error }] = useValidateCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateCodeMutation, ValidateCodeMutationVariables>
) {
  return Apollo.useMutation<ValidateCodeMutation, ValidateCodeMutationVariables>(
    ValidateCodeDocument,
    baseOptions
  );
}
export type ValidateCodeMutationHookResult = ReturnType<typeof useValidateCodeMutation>;
export type ValidateCodeMutationResult = Apollo.MutationResult<ValidateCodeMutation>;
export type ValidateCodeMutationOptions = Apollo.BaseMutationOptions<
  ValidateCodeMutation,
  ValidateCodeMutationVariables
>;
