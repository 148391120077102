import { InMemoryCache } from '@apollo/client';
import { GetXBrandQuery, GetXBrandDocument } from '../generated/graphql';
import { getSharedConfig } from './configs';

export function getXBrandFromCache(cache: InMemoryCache): number {
  const query = cache.readQuery<GetXBrandQuery>({
    query: GetXBrandDocument,
  });

  if (!query?.xbrand) {
    const config = getSharedConfig();
    return config.defaultBrand;
  }

  return query.xbrand;
}
