import { Endpoints } from '../../../common/model/config';

export const onyoComEndpoints: Endpoints = {
  api: 'https://api.onyo.com',
  graphql: 'https://graphql.onyo.com/graphql',
};

export const onyoComStagingEndpoints: Endpoints = {
  api: 'https://api.staging.onyo.com',
  graphql: 'https://graphql.staging.onyo.com/graphql',
};
